import './App.css';
import { Image } from 'primereact/image';
import logo from './images/logo-pagina.jpg';
import { Button } from 'primereact/button';

function App() {
  return (
    <div className="App">
      <header className='App-header'>
        <Image src={logo} width="150px"/>
      </header>
      <div className='App-main'>
        <div className='App-text-container'>
          <p>
            We are working on a new site
          </p>
          <p>
            Mariano Ferrante is represented by the Arts Projects International Gallery NY.
          </p>
          <Button onClick={() => {
          window.location.href = "https://artprojects.com/mariano-ferrante/mariano-ferrante-selected-works/"
        }} label="Art Projects International, New York " className="p-button-raised p-button-rounded p-button-info"/>
        </div>
        <Button onClick={() => {
          window.location.href = "https://www.instagram.com/mariano_ferrante/"
        }} icon="pi pi-instagram" className="p-button p-component p-button-rounded p-button-info p-button-icon-only"/>
      </div>
    </div>
  );
}

export default App;
